import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Health Tracker App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/health-tracking-mobile-app/"
    metaDescription="Track your cycle, track your mood… our health tracker app design template, MediTrack, is the perfect template for your health tracker app design."
    description="
    h2:Say hello to MediTrack, our health tracker app design template for mobile
    <br/>
    Whether you are tracking workouts, mood, or even weight loss, our mobile health tracker design template is here to streamline your design flow. Make slow design with outdated prototyping and <a:https://uizard.io/wireframing/>UI wireframing tools</a> a thing of the past with our easy-to-use templates. Simply sign up to Uizard, open MediTrack and bring your mobile app design vision to life at lightning speed.
    <br/>
    h3:MediTrack makes never ending design cycles a thing of the past
    <br/>
    MediTrack is built with speed in mind. Want to move fast with your digital product design flow? You can use our health tracker app template straight out the box. Want to spend a bit of time aligning the design with your own service or MVP? Uizard supports limitless customization to really help you transform ideas into design reality.
    <br/>
    h3:With Uizard you can track your design project easier than ever before 
    <br/>
    With Uizard's powerful smart collaboration features, communication between you and your team is faster than ever before. Simply share your Uizard project and begin working together rapidly in real time. You can even share your <a:https://uizard.io/prototyping/>UI prototype</a> with potential customers to really get a feel for how your finished product will perform.
    "
    pages={[
      "User onboarding process screens",
      "Various expertly designed health data tracking screens ",
      "Daily data overview for your prospective users",
      "A refined user profile with data and analytics",
    ]}
    projectCode="vezm1Q11Kjtyomq4dEBL"
    img1={data.image1.childImageSharp}
    img1alt="mobile health tacker app design overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile health tacker design profile screen"
    img3={data.image3.childImageSharp}
    img3alt="mobile health tacker design review screen"
    img4={data.image4.childImageSharp}
    img4alt="mobile health tacker design cycle screen"
    img5={data.image5.childImageSharp}
    img5alt="mobile health tacker design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/health-tracking-mobile-app/health-tracker-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/health-tracking-mobile-app/health-tracker-app-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/health-tracking-mobile-app/health-tracker-app-overview.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/health-tracking-mobile-app/health-tracker-app-cycle.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/health-tracking-mobile-app/health-tracker-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
